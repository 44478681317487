import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const QuizzRegistrationForm = ({ titleEn, titlePt, registrationDeadline }) => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const navigate = useNavigate(); // For redirection after submission

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    formType: "quizzRegistration", // Unique identifier for the quiz form
    eventName: titleEn, // Use English title as a default event name
  });

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const dataToSend = { ...formData };

    try {
      const response = await fetch(
        "https://us-central1-cpaa-ac0ff.cloudfunctions.net/sendEmail",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dataToSend),
        }
      );
      if (response.ok) {
        alert("Inscrição no Quizz enviada com sucesso!");
        navigate("/"); // Redirect to homepage
      } else {
        alert("Failed to submit the quiz registration form.");
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
      alert("An error occurred while submitting the form.");
    }
  };

  return (
    <form className="registration-form" onSubmit={handleSubmit}>
      <h2 className="registration-title">
        {language === "en" ? titleEn : titlePt}
      </h2>
      <p className="form-requirement">
        {t("ArticleDetailPage.ArticleDetailPage29")}
      </p>

      <div className="form-group">
        <label htmlFor="name">{t("ArticleDetailPage.ArticleDetailPage2.1")}</label>
        <input
          type="text"
          id="name"
          name="name"
          required
          value={formData.name}
          onChange={handleInputChange}
        />
      </div>
      <div className="form-group">
            <label htmlFor="memberNumber">
              {t("ArticleDetailPage.ArticleDetailPage3")}
            </label>
            <input
              type="text"
              id="memberNumber"
              required
              value={formData.memberNumber}
              onChange={handleInputChange}
            />
          </div>
      <div className="form-group">
        <label htmlFor="email">{t("ArticleDetailPage.ArticleDetailPage4")}</label>
        <input
          type="email"
          id="email"
          name="email"
          required
          value={formData.email}
          onChange={handleInputChange}
        />
      </div>

      <div className="form-group">
        <label htmlFor="phone">{t("ArticleDetailPage.ArticleDetailPage5")}</label>
        <input
          type="text"
          id="phone"
          name="phone"
          required
          value={formData.phone}
          onChange={handleInputChange}
        />
      </div>

      <div className="additional-info">
            <p>{t("ArticleDetailPage.ArticleDetailPage22")}</p>
            
           
            <p>
              {t("ArticleDetailPage.ArticleDetailPage24")}{" "}
              {registrationDeadline || "2024-12-07"}
            </p>{" "}
        
            
            <p>{t("ArticleDetailPage.ArticleDetailPage27")}</p>
          </div>

      <button type="submit" className="submit-button">
        {t("ArticleDetailPage.ArticleDetailPage28")}
      </button>
    </form>
  );
};

export default QuizzRegistrationForm;
