import React, { useState, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';

const ImageGalleryBlock = ({ images }) => {
    const [galleryImages, setGalleryImages] = useState(images);
    const [selectedImageIndex, setSelectedImageIndex] = useState(null);

    // 🔴 Fix: Ensure the gallery updates when Firestore updates images
    useEffect(() => {
        setGalleryImages(images);
    }, [images]); // 🔥 Re-renders when `images` changes

    const handleImageClick = (index) => {
        setSelectedImageIndex(index);
    };

    const closeImageModal = (event) => {
        event.stopPropagation(); // Stop click from closing the modal
        setSelectedImageIndex(null);
    };

    const handleNext = (event) => {
        event.stopPropagation(); // Prevent modal from closing
        if (selectedImageIndex < galleryImages.length - 1) {
            setSelectedImageIndex(selectedImageIndex + 1);
        }
    };

    const handlePrev = (event) => {
        event.stopPropagation(); // Prevent modal from closing
        if (selectedImageIndex > 0) {
            setSelectedImageIndex(selectedImageIndex - 1);
        }
    };

    const swipeHandlers = useSwipeable({
        onSwipedLeft: () => handleNext(new Event('swipe')),
        onSwipedRight: () => handlePrev(new Event('swipe')),
    });

    return (
        <div className="image-gallery">
            {galleryImages.map((img, index) => (
                <img 
                    key={index} 
                    src={img} 
                    alt={`Gallery image ${index}`} 
                    className="gallery-image"
                    onClick={() => handleImageClick(index)}
                />
            ))}
            {selectedImageIndex !== null && (
                <div className="image-modal" onClick={() => setSelectedImageIndex(null)} {...swipeHandlers}>
                    <img src={galleryImages[selectedImageIndex]} alt="Enlarged view" className="enlarged-image" onClick={e => e.stopPropagation()} />
                    <button className="close-modal" onClick={closeImageModal}>✕</button>
                    {selectedImageIndex > 0 && (
                        <button className="modal-prev" onClick={handlePrev}>&#10094;</button>
                    )}
                    {selectedImageIndex < galleryImages.length - 1 && (
                        <button className="modal-next" onClick={handleNext}>&#10095;</button>
                    )}
                </div>
            )}
        </div>
    );
};

export default ImageGalleryBlock;

