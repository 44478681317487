import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { firestore } from "../firebase-config";
import {
  getDocs,
  collection,
  query,
  where,
  Timestamp,
} from "firebase/firestore";
import ParagraphBlock from "./Articles/ParagraphBlock";
import ImageGalleryBlock from "./Articles/ImageGalleryBlock";
import VideoBlock from "./Articles/VideoBlock";
import AuthorImageBlock from "./Articles/AuthorImageBlock";
import RegistrationFormBlock from "./Articles/RegistrationFormBlock";
import RegistrationFormPorto from "./Articles/RegistrationFormPorto";
import RegistrationFormLisbon from "./Articles/RegistrationFormLisbon";
import SubtitleBlock from "./Articles/SubtitleBlock";
import RandomPageImageBlock from "./Articles/RandomPageImageBlock";
import YouTubeVideoBlock from "./Articles/YouTubeVideoBlock";
import Footer from "./Geral/Footer";
import ParentComponent from "./Geral/ParentComponent";
import "./Articles/ArticleDetailPage.css";
import { useTranslation } from "react-i18next";
import TitleBlock from "./Articles/TitleBlock";
import ProgramBlock from "./Articles/ProgramBlock";
import { onSnapshot, doc } from "firebase/firestore";
import QuizzRegistrationForm from "./Articles/QuizzRegistrationForm";

const ArticleDetailPage = () => {
  const { slug } = useParams();
  const [article, setArticle] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (!slug) return;

    const slugParts = decodeURIComponent(slug).split("-");
    const createdAt = slugParts.pop();
    const titlePt = slugParts.join("-").replace(/_/g, " ");

    const createdAtTimestamp = parseInt(createdAt, 10);
    if (isNaN(createdAtTimestamp)) return;

    const q = query(
        collection(firestore, "articles"),
        where("titlePt", "==", titlePt),
        where("createdAt", "==", Timestamp.fromMillis(createdAtTimestamp))
    );

    // 🔴 Real-time Firestore listener
    const unsubscribe = onSnapshot(q, (snapshot) => {
        if (!snapshot.empty) {
            setArticle(snapshot.docs[0].data());
        }
    });

    return () => unsubscribe(); // Cleanup listener on unmount
}, [slug]);


const renderContentBlock = (block) => {
  switch (block.type) {
    case "paragraph":
      return <ParagraphBlock content={block.content} />;
    case "imageGallery":
      return <ImageGalleryBlock images={block.images} />;
    case "video":
      return <VideoBlock url={block.content} />;
    case "authorImage":
      return <AuthorImageBlock imageUrl={block.content} />;
    case "registrationForm":
      return <RegistrationFormBlock {...block.details} />;
    case "portoRegistrationForm":
      return <RegistrationFormPorto {...block.details} />;
    case "lisbonRegistrationForm":
      return <RegistrationFormLisbon {...block.details} />;
    case "quizzRegistrationForm": // ✅ Add support for quiz registration form
      return <QuizzRegistrationForm {...block.details} />;
    case "subtitle":
      return <SubtitleBlock content={block.content} />;
    case "randomPageImage":
      return <RandomPageImageBlock image={block.content} />;
    case "youtubeVideo":
      return <YouTubeVideoBlock url={block.content} />;
    case "title":
      return <TitleBlock titleEn={block.titleEn} titlePt={block.titlePt} />;
    case "program":
      if (block.details) {
        return (
          <ProgramBlock
            dateEn={block.details.dateEn}
            datePt={block.details.datePt}
            activities={block.details.activities}
          />
        );
      }
      break;
    default:
      return null;
  }
};


  return (
    <div>
      <ParentComponent />
      {article ? (
        <div className="wrapper">
          {/* Use headerImage first, fallback to mainImage if missing */}
          {/* Use bannerImage first, fallback to mainImage if missing */}
          <img
            src={article.bannerImage || article.mainImage}
            alt={article.titleEn || article.titlePt || "Article Image"}
            className="main-article-image"
          />

          <TitleBlock titleEn={article.titleEn} titlePt={article.titlePt} />
          {article.contentBlocks &&
            article.contentBlocks.map((block, index) => (
              <div key={index} className="content-block">
                {renderContentBlock(block)}
              </div>
            ))}
        </div>
      ) : (
        <p>Loading...</p>
      )}
      <Footer />
    </div>
  );
};

export default ArticleDetailPage;
