import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const RegistrationFormLisbon = ({
  titleEn,
  titlePt,
  bedOption,
  carTwoPeopleBed,
  carTwoPeopleSeparate,
  carTwoPeopleNoBed,
  carOnePerson,
  oneCompanion,
  twoCompanions,
  threeCompanions,
  registrationDeadline,
}) => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const navigate = useNavigate(); // Initialize navigate hook

  const [selectedCarOption, setSelectedCarOption] = useState("");
  const [selectedCompanionOption, setSelectedCompanionOption] = useState("");
  const [formData, setFormData] = useState({
    driver: "",
    memberNumber: "",
    email: "",
    phone: "",
    companion: "",
    brand: "",
    model: "",
    plate: "",
    year: "",
    foodRestrictions: "",
    formType: "lisbonRegistration", // Specific form type for Lisbon
    eventName: titleEn, // Use the English title as a default event name
  });

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  const handleCarOptionChange = (event) => {
    setSelectedCarOption(event.target.value);
  };

  const handleCompanionOptionChange = (event) => {
    setSelectedCompanionOption(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const dataToSend = {
      ...formData,
      selectedCarOption,
      selectedCompanionOption,
    };

    try {
      const response = await fetch(
        "https://us-central1-cpaa-ac0ff.cloudfunctions.net/sendEmail",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dataToSend),
        }
      );
      if (response.ok) {
        alert("Formulário enviado com sucesso!");
        navigate("/"); // Redirect to homepage
      } else {
        alert(`Falha ao enviar o Formulário!`);
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
      alert("An error occurred while submitting the form.");
    }
  };

  return (
    <form className="registration-form" onSubmit={handleSubmit}>
      <h2 className="registration-title">
        {language === "en" ? titleEn : titlePt}
      </h2>
      <p className="form-requirement">
        {t("ArticleDetailPage.ArticleDetailPage29")}
      </p>
      <div className="form-group">
        <label htmlFor="driver">
          {t("ArticleDetailPage.ArticleDetailPage2")}
        </label>
        <input
          type="text"
          id="driver"
          name="driver"
          required
          value={formData.driver}
          onChange={handleInputChange}
        />
      </div>
      <div className="form-group">
        <label htmlFor="memberNumber">
          {t("ArticleDetailPage.ArticleDetailPage3")}
        </label>
        <input
          type="text"
          id="memberNumber"
          name="memberNumber"
          required
          value={formData.memberNumber}
          onChange={handleInputChange}
        />
      </div>
      <div className="form-group">
        <label htmlFor="email">
          {t("ArticleDetailPage.ArticleDetailPage4")}
        </label>
        <input
          type="email"
          id="email"
          name="email"
          required
          value={formData.email}
          onChange={handleInputChange}
        />
      </div>
      <div className="form-group">
        <label htmlFor="phone">
          {t("ArticleDetailPage.ArticleDetailPage5")}
        </label>
        <input
          type="text"
          id="phone"
          name="phone"
          required
          value={formData.phone}
          onChange={handleInputChange}
        />
      </div>
      <div className="form-group">
        <label htmlFor="companion">
          {t("ArticleDetailPage.ArticleDetailPage6")}
        </label>
        <input
          type="text"
          id="companion"
          name="companion"
          value={formData.companion}
          onChange={handleInputChange}
        />
      </div>
      <div className="form-group">
        <label htmlFor="brand">
          {t("ArticleDetailPage.ArticleDetailPage7")}
        </label>
        <input
          type="text"
          id="brand"
          name="brand"
          required
          value={formData.brand}
          onChange={handleInputChange}
        />
      </div>
      <div className="form-group">
        <label htmlFor="model">
          {t("ArticleDetailPage.ArticleDetailPage8")}
        </label>
        <input
          type="text"
          id="model"
          name="model"
          required
          value={formData.model}
          onChange={handleInputChange}
        />
      </div>
      <div className="form-group">
        <label htmlFor="plate">
          {t("ArticleDetailPage.ArticleDetailPage9")}
        </label>
        <input
          type="text"
          id="plate"
          name="plate"
          required
          value={formData.plate}
          onChange={handleInputChange}
        />
      </div>
      <div className="form-group">
        <label htmlFor="year">
          {t("ArticleDetailPage.ArticleDetailPage10")}
          <span
            style={{ color: "orange", fontWeight: "bold", marginLeft: "10px" }}
          >
            {t("ArticleDetailPage.ArticleDetailPage10.1")}
          </span>
        </label>
        <select
          id="year"
          name="year"
          required
          value={formData.year}
          onChange={handleInputChange}
          className="styled-select" // ✅ Use the same class as inputs
        >
          <option value="">
            {t("ArticleDetailPage.ArticleDetailPage10.2")}
          </option>
          {Array.from({ length: 1995 - 1900 }, (_, i) => 1995 - i).map(
            (year) => (
              <option key={year} value={year} disabled={year > 1995}>
                {year}
              </option>
            )
          )}
        </select>
      </div>
      <div className="pricing-options">
        <h3>{t("ArticleDetailPage.ArticleDetailPage11")}</h3>
        {bedOption ? (
          <>
            <label>
              <input
                type="radio"
                name="carOption"
                value="twoPeopleBed"
                onChange={handleCarOptionChange}
                checked={selectedCarOption === "twoPeopleBed"}
              />
              {t("ArticleDetailPage.ArticleDetailPage12")}
              {carTwoPeopleBed} €({t("ArticleDetailPage.ArticleDetailPage13")})
            </label>
            <label>
              <input
                type="radio"
                name="carOption"
                value="twoPeopleSeparate"
                onChange={handleCarOptionChange}
                checked={selectedCarOption === "twoPeopleSeparate"}
              />
              {t("ArticleDetailPage.ArticleDetailPage14")}
              {carTwoPeopleSeparate} €(
              {t("ArticleDetailPage.ArticleDetailPage15")})
            </label>
          </>
        ) : (
          <>
            <label>
              <input
                type="radio"
                name="carOption"
                value="carTwoPeopleNoBed"
                onChange={handleCarOptionChange}
                checked={selectedCarOption === "carTwoPeopleNoBed"}
              />
              {t("ArticleDetailPage.ArticleDetailPage12")}
              {carTwoPeopleNoBed} €
            </label>
          </>
        )}
        <label>
          <input
            type="radio"
            name="carOption"
            value="onePerson"
            onChange={handleCarOptionChange}
            checked={selectedCarOption === "onePerson"}
          />
          {t("ArticleDetailPage.ArticleDetailPage16")}
          {carOnePerson} €
        </label>
        <h3>{t("ArticleDetailPage.ArticleDetailPage17")}</h3>
        <label>
          <input
            type="radio"
            name="companionOption"
            value="oneCompanion"
            onChange={handleCompanionOptionChange}
            checked={selectedCompanionOption === "oneCompanion"}
          />
          {t("ArticleDetailPage.ArticleDetailPage18")}
          {oneCompanion} €
        </label>
        <label>
          <input
            type="radio"
            name="companionOption"
            value="twoCompanions"
            onChange={handleCompanionOptionChange}
            checked={selectedCompanionOption === "twoCompanions"}
          />
          {t("ArticleDetailPage.ArticleDetailPage19")}
          {twoCompanions} €
        </label>
        <label>
          <input
            type="radio"
            name="companionOption"
            value="threeCompanions"
            onChange={handleCompanionOptionChange}
            checked={selectedCompanionOption === "threeCompanions"}
          />
          {t("ArticleDetailPage.ArticleDetailPage20")}
          {threeCompanions} €
        </label>
      </div>
      <div className="form-group">
        <label htmlFor="foodRestrictions">
          {t("ArticleDetailPage.ArticleDetailPage21")}
        </label>
        <input
          type="text"
          id="foodRestrictions"
          name="foodRestrictions"
          value={formData.foodRestrictions}
          onChange={handleInputChange}
        />
      </div>
      <div className="additional-info">
        <p>{t("ArticleDetailPage.ArticleDetailPage22")}</p>
        <p>{t("ArticleDetailPage.ArticleDetailPage231")}</p>
        <p>IBAN PT50 0033.0000.0004.7957.6740.5 - Millennium BCP</p>
        <p>
          {t("ArticleDetailPage.ArticleDetailPage24")}: {registrationDeadline}
        </p>
        <p>{t("ArticleDetailPage.ArticleDetailPage26")}</p>
        <p>{t("ArticleDetailPage.ArticleDetailPage271")}</p>
      </div>
      <button type="submit" className="submit-button">
        {t("ArticleDetailPage.ArticleDetailPage28")}
      </button>
    </form>
  );
};

export default RegistrationFormLisbon;
